import React from 'react';
import styled from 'styled-components';
import { Fullscreen } from '../components/fullscreen/fullscreen';
import { Background } from '../components/background/background';

import cover from '../../static/images/services/cover.png';
import Layout from '../components/layout/layout';
import { PRIMARY_TEXT_COLOR, SECONDARY_BG_COLOR, webBackend, webFrontend } from '../constants';
import { Container } from 'reactstrap';
import { LetsGetStarted } from '../components/shared/lets-get-started';
import { TextBlock } from '../components/shared/text-block';
import { Timeline } from '../components/about/process-timeline';
import { TitleContainer, TitleText } from '../components/shared';
import { MAX_PAGES } from '.';
import { useScrollingPage } from '../components/shared/scrolling-page.hook';
import { designServices, mobileServices } from '../constants';
const Wrapper = styled.div`
    height: 100%;
    background-color: ${props => props.color};
    padding-bottom: 50px;

    &:not(:first-of-type) {
        padding-top: 50px;
    }
`;

export default () => {
    const { canScroll, setCanScroll } = useScrollingPage(MAX_PAGES);
    return (
        <Layout setCanScroll={setCanScroll} canScroll={canScroll}>
            <Wrapper>
                <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
                    <Background bg={cover} design={'cover'} index={1} />
                    <TitleContainer>
                        <TitleText>Services.</TitleText>
                    </TitleContainer>
                </Fullscreen>
                <Container>
                    <TextBlock title="What we do." txtcolor={PRIMARY_TEXT_COLOR}>
                        <p>
                            From development to deployment, setup to maintenance, we do it all. As experts in web, native mobile, backend
                            and blockchain solutions, we always rise to the challenge with smart software implemented efficiently.
                            Strategize, implement and test — are the steps we always follow. We treat each client as an individual and each
                            solution as an exciting challenge to overcome.
                        </p>
                        <p>
                            We foster a culture of the best sharpening the best and have worked hard to create an environment dedicated to
                            moving forward smartly. Our team has logged over 4 million development hours with our executive team having a
                            combined experience of over 150 years, you know you are in great hands. We have the experience and award-winning
                            knowledge to ensure your vision becomes a reality.
                        </p>
                    </TextBlock>
                    <TextBlock title="Services." txtcolor={PRIMARY_TEXT_COLOR}>
                        <h2>Frontend</h2>
                        <p>
                            We specialize in building and optimizing frontend interfaces that deliver exceptional user experiences, serving
                            millions of users every month. Utilizing React, Angular, and a range of cutting-edge UI libraries, we create
                            fast, responsive, and scalable platforms. Our team stays ahead of emerging trends and technologies to ensure the
                            most seamless, user-friendly designs, continually enhancing our capabilities to meet the ever-evolving needs of
                            the digital landscape.
                        </p>
                        <div>
                            {webFrontend.map(service => {
                                return <span className="badge badge-warning mr-2">{service}</span>;
                            })}
                        </div>
                        <h2 className="mt-5">Backend</h2>
                        <p>
                            We build and maintain backend systems and web platforms that have millions of monthly users. We are experts in
                            several web technologies and we are continuously adding to our expertise by researching and using new
                            technologies.
                        </p>
                        <div>
                            {webBackend.map(service => {
                                return <span className="badge badge-warning mr-2">{service}</span>;
                            })}
                        </div>
                        <h2 className="mt-5">Native mobile apps development</h2>
                        <p>
                            Our team has the expertise to develop your app from the ground up. We have helped launch successful app products
                            in the fintech, healthcare, transportation and wellbeing space and many more. Our award-winning app development
                            produces excellently designed digital products that we deliver on time and on budget. Our team of experts will
                            guide your project from the drawing board to reality.
                        </p>
                        <div>
                            {mobileServices.map(service => {
                                return <span className="badge badge-warning mr-2">{service}</span>;
                            })}
                        </div>
                        <h2 className="mt-5">Design</h2>
                        <p>
                            We offer design sprint, design leadership and UX/UI design for your project - end to end. From the spark of the
                            first idea to the end delivery and implementation with developers (ours or yours). We strive to design the best
                            solution possible for your specific business needs. Our key focus to find the right problems to solve for the
                            customer so that they will have a great experience. Great user experiences get users to interact more with your
                            business. It’s a simple concept, but one that so many fail to get right.
                        </p>
                        <p>
                            The design services we include are ideation, design sprint workshops, user research, UX design (sketching,
                            wireframing), low fidelity prototyping, high fidelity prototyping, user testing and UI design.
                        </p>
                        <div>
                            {designServices.map(service => {
                                return <span className="badge badge-warning mr-2">{service}</span>;
                            })}
                        </div>
                    </TextBlock>
                </Container>
            </Wrapper>
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <Container>
                    <TextBlock title={'How we work.'}>
                        <h2>Our process.</h2>
                        <p>
                            Today, people embrace companies with the customer experiences that best meet their needs. Equaleyes transforms
                            companies by designing these best-in-class experiences, driven by strong technology, data, and organizational
                            strategy required for operational excellence. We combine these capabilities into a single solution, creating
                            unified brand experiences that change industries and foster meaningful relationships with users.
                        </p>
                    </TextBlock>
                    <Timeline />
                </Container>
            </Wrapper>
            <Wrapper color={SECONDARY_BG_COLOR}>
                <LetsGetStarted bg={SECONDARY_BG_COLOR} color={PRIMARY_TEXT_COLOR} />
            </Wrapper>
        </Layout>
    );
};
